<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>愿景与使命</h3>
    </div>
    <el-divider></el-divider>
    <div class="content">
      <section class="met-editor clearfix">
        <table
          width="1161"
          class="table table-striped table-bordered table-hover"
        >
          <tbody style="padding: 0px; margin: 0px">
            <tr class="firstRow" style="padding: 0px; margin: 0px"></tr>
          </tbody>
        </table>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span
            style="
              padding: 0px;
              margin: 0px;
              font-family: 微软雅黑, MicrosoftYaHei;
              font-size: 14px;
              color: rgb(89, 89, 89);
            "
            ><strong style="padding: 0px; margin: 0px">愿景</strong></span
          >
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong style="padding: 0px; margin: 0px"
              >成为最受社会、客户、股东、员工尊敬的公司
有社会责任感，遵纪守法，保护环境，追求企业成长与社会发展的和谐

尊重客户的需求与投资，通过创新，过程改进，员工的发展，不断地为客户创造价值

不断追求公司经营结果的改进，为投资者带来长期良好的回报

尊重员工的个性，为他们竞争能力的提升和快乐工作而投资，并为他们的贡献给予合理的回报</strong
            ><span
              style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 16px"
              ><span
                style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 16px"
                microsoft=""
                ><strong style="padding: 0px; margin: 0px"></strong></span
              ><strong style="padding: 0px; margin: 0px"></strong></span
            ><strong style="padding: 0px; margin: 0px"></strong
            ><br data-filtered="filtered" style="padding: 0px; margin: 0px"
          /></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong style="padding: 0px; margin: 0px"><br /></strong
          ></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong>使命</strong
            ><strong>。</strong></span
          >
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong><br /></strong
          ></span>
        </p>
        <p
          class="ordinary-output target-output"
          style="
            padding: 0px;
            margin-bottom: 0px;
            word-wrap: break-word;
            word-break: normal;
            color: rgb(106, 106, 106);
            font-family: Arial, 微软雅黑;
            font-size: 13px;
            white-space: normal;
            background-color: rgb(248, 248, 248);
          "
        >
          <span style="font-family: 微软雅黑, MicrosoftYaHei; font-size: 14px"
            ><strong
              >通过组织与过程的持续改进，领导力与员工竞争力发展，联盟和开放式创新，
使中软汇金成为全球优秀的IT解决方案与服务供应商</strong
            ></span
          >
        </p>
      </section>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>